import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import LP from "./Biba";
//import LP from "./Care";
//import LP from "./Carev2";
//import LP from "./Sp";
//import LP from "./TowergateNoMap";
//import LP from "./Towergate";
//import LP from "./CreditsV2";
import LP from "./Credits";
//import LP from "./Willis";
//import LP from "./Avam";
//import LP from "./Apil";
//import LP from "./Scil";
//import LP from "./Leadingbyexample";
//import LP from "./Cobra";
//import LP from "./ExpectMore";
//import LP from "./Hedron";
//import LP from "./Biba2022";
//import LP from "./Bravo";
//import LP from "./BrokerFest";
//import LP from "./UK200";
//import LP from "./Caunce";
//import LP from "./Acturis";
//import LP from "./ActurisV2";
//import LP from "./Roadshow";
//import reportWebVitals from "./reportWebVitals";

//import LP from "./eventbrite";
//import LP from "./wtw";


import "airbnb-browser-shims";

ReactDOM.render(
  <React.StrictMode>
    <LP />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
